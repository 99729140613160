import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button, List, ListItem, ListItemText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { numberToCurrency } from "../../../utils/numbers.utils";
import { createSellPosThunk, setShowSureSellDialogAct } from "../sell.slice";

export default function DialogSureSaveSell() {
  const dispatch = useAppDispatch()
  const { formSell, raffles, ticketPrice, showSureSellDialog } = useAppSelector(((state) => state.sell))
  const closeDialog = () => dispatch(setShowSureSellDialogAct(false))
  return (
    <>
      <Dialog open={showSureSellDialog}>
        <IconButton onClick={closeDialog} className="closeDialog"> <Close/> </IconButton>
        <DialogTitle> Confirmar venta </DialogTitle>
        <DialogContent sx={{minWidth: 500}}>
          Confirmar creacion de venta:
          <List>
            <ListItem>
              <ListItemText
                primary="Sorteo"
                secondary={raffles.find(r => r._id === formSell.raffle)?.name}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Cantidad Tickets"
                secondary={formSell.nTickets}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Total"
                secondary={`$${numberToCurrency(formSell.nTickets * ticketPrice)}`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button size="small" color="error" onClick={closeDialog} variant="outlined">CANCELAR</Button>
          <Button size="small" color="success" variant="outlined" onClick={() => dispatch(createSellPosThunk(formSell))}>ACEPTAR</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}