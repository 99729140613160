import { Check, Close } from "@mui/icons-material";
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setShowSuccessSellDialogAct } from "../sell.slice";

export default function DialgoSuccessSell() {
  const dispatch = useAppDispatch()
  const { showSuccessSellDialog } = useAppSelector((state) => state.sell)

  const closeDialog = () => {
    dispatch(setShowSuccessSellDialogAct(false))
  }
  return (
    <>
      <Dialog open={showSuccessSellDialog} onKeyDown={closeDialog}>
        <IconButton onClick={closeDialog} className="closeDialog"> <Close/> </IconButton>
        <DialogTitle> Venta exitosa </DialogTitle>
        <DialogContent sx={{minWidth: 500}}> 
          <Typography>
            Venta realizada con exito  <Check color="success"/> 
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={closeDialog} color="success" variant="outlined">ACEPTAR</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}