import Api from "../axios"
import { RaffleSellData } from "../models/raffle-sell.type";

const api = new Api()

export async function addPaymentReq(data: { cardId: string; value: number }) {
  try {
    const addPayment = await api.post({
      path: `payments/add-payment/`,
      data: { ...data, value: Number(data.value) },
    })
    console.log({ addPayment })
    const { error } = addPayment
    if (error == null) {
      if (addPayment.result.newPayment !== undefined) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (error) {
    throw error
  }
}


export async function getSellDataReq({sellId} : {sellId : string}): Promise<RaffleSellData>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `raffle-sells/${sellId}`})
    console.log('getSellData', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getSellData');
    console.error({error});
    throw error;
  }
}