/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom"
import { CheckUserAllowedComponent } from "../app/components/check-user-allowed-component"
import UsersListTable from "../features/users-list/components/users-list-table"

export default function UsersListView() {
  const navigate  = useNavigate()

  const notAllowed = (allowed: boolean) => {
    if(!allowed){
      navigate("/dashboard")
    }
  }
  return (
    <>
      <CheckUserAllowedComponent checkIfAdmin={true} onCheckPermission={notAllowed}>
        <UsersListTable />
      </CheckUserAllowedComponent>
    </>
  )
}
