import { Box, Card, Divider, Typography } from "@mui/material"
import RaffleHeadForm from "../features/raffles/components/raffle-head-form"
import DialogRaffleError from "../features/raffles/components/dialog-raffle-error"
import RaffleImagesHandler from "../features/raffles/components/raffle-images-handler"
import RafflePrizesHandler from "../features/raffles/components/raffle-prizes-handler"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useAppDispatch } from "../app/hooks"
import { resetFormAct } from "../features/raffles/handle-raffle.slice"

export default function HandleRaffleView() {
  const { raffleId } = useParams()
  const dispatch = useAppDispatch()  

  useEffect(() => {
  if (!raffleId) {
      dispatch(resetFormAct())
    }
  }, [])


  return (
    <Box padding={1}>
      <DialogRaffleError/>
      <Card sx={{ padding: "5px", marginBottom: "10px" }}>
        <Typography variant="h6">
          {raffleId ? "Editar Sorteo" : "Agregar Sorteo"}
        </Typography>
        <RaffleHeadForm/>
      </Card>
      <RaffleImagesHandler/>
      <Divider />
      <RafflePrizesHandler/>
    </Box>
  )
}