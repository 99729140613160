import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SetWinnerSliceState } from "./set-winner.state";
import { getRaffleSoldNumbersReq, isSoldNumberReq } from "../../app/services/sell.service";
import { setNumberWinnerReq } from "../../app/services/raffle.service";

const initialState: SetWinnerSliceState = {
  loading: false,
  userValidated: false,
  showDialogSucessRaffleDone: false,
  soldNumbers: [],
  form: {
    raffleId: '',
    numberWinner: ''
  },
  showDialogSureNumberWinner: false,
  numberForFind: ''
}
export const getSoldNumbersThunk = createAsyncThunk( "SetWinnerSlice/getSoldNumbersThunk", async ({raffleId} : {raffleId: string}) => await getRaffleSoldNumbersReq({raffleId}))

export const findSoldNumberInfo = createAsyncThunk( "SetWinnerSlice/findSoldNumberInfo", async (params: {number: string, raffleId: string}) => await isSoldNumberReq(params))

export const setNumberWinnerThunk = createAsyncThunk( "SetWinnerSlice/setNumberWinnerThunk", async (PARAM: {number: string, raffleId: string}) => await setNumberWinnerReq({raffleId: PARAM.raffleId, number: PARAM.number}))

export const SetWinnerSlice = createSlice({
  name: "SetWinnerSlice",
  initialState,
  reducers: {
    closeDialogRaffleDoneAct: (state) => {  
      state.showDialogSucessRaffleDone = false
      state.form.raffleId = ''
      state.form.numberWinner = ''
    },
    changeInputSetWinnerAct: (state, action: PayloadAction<{name: string, val: string}>) => {
      state.form[action.payload.name] = action.payload.val
    },
    changeInputFindNumberAct: (state, action: PayloadAction<string>) => { 
      state.infoNumber = undefined
      state.numberForFind = action.payload
    },
    closeDialogInfoNumberAct: (state) => {
      state.infoNumber = undefined
    }, 
    showDialogSureNumberWinnerAct: (state, action: PayloadAction<boolean>) => {
      state.showDialogSureNumberWinner = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSoldNumbersThunk.fulfilled, (state, action) => {
      state.soldNumbers = action.payload
    }).addCase(findSoldNumberInfo.fulfilled, (state, action) => {
      if(action.payload.length > 0) {
        state.infoNumber = action.payload[0]
      }else {
        state.infoNumber = null
      }

    }).addCase(setNumberWinnerThunk.fulfilled, (state, action) => {
      state.showDialogSucessRaffleDone = true
      state.showDialogSureNumberWinner = false
      if(action.payload.infoWinner.length > 0) state.numberWinner = action.payload.infoWinner[0]
    })

    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("SetWinnerSlice"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("SetWinnerSlice"), (state) => {
      state.loading = false
    })
  },
})
export const { changeInputSetWinnerAct, changeInputFindNumberAct, closeDialogInfoNumberAct, showDialogSureNumberWinnerAct, closeDialogRaffleDoneAct } =SetWinnerSlice.actions

export default SetWinnerSlice.reducer