import { Button, ButtonGroup, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useNavigate } from "react-router-dom";
import { Checklist, Lock, Settings } from "@mui/icons-material";
import LoadingIndicator from "../../../app/components/loading-indicator";
import { setDialogRaffleSureNumberWinnersAct, setRaffleForDisableAct, showRaffleSettingsDialogAct } from "../rafles-list.slice";
import { RaffleUtils } from "../../../utils/raffle.utils";
import RaffleSettingsDialog from "./raffle-settings-dialog";

export default function RafflesList () {
  const { raffles, loading } = useAppSelector((state: RootState) => state.raflesList)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const editRaffle = (raffleId: string) => {
    navigate(`/dashboard/handel-raffle/${raffleId}`)
  }
  return (
    <>
      <RaffleSettingsDialog/>
      <LoadingIndicator open={loading}/>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell>N Imgs</TableCell>
              <TableCell colSpan={2}>Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {raffles.map((raffle) => {
              return (
                <TableRow key={raffle._id}>
                  <TableCell>{raffle.datePrize.split("T")[0]}</TableCell>
                  <TableCell>{raffle.name}</TableCell>
                  <TableCell>{RaffleUtils.resolveStatus(raffle.status)}</TableCell>
                  <TableCell> <Button variant="outlined" size="small" onClick={() => dispatch(setDialogRaffleSureNumberWinnersAct({
                    raffleId: raffle._id,
                    raffleName: raffle.name,
                    inputNumber: "",
                    nTicket: 0,
                    maxLength: raffle.numberLength,
                    numbers: raffle.numberSureWinners,
                    prize: 0
                  }))}> <Checklist fontSize="small"/> </Button> </TableCell>
                  <TableCell>
                    <Button color="secondary" variant="outlined" size="small"
                      onClick={() => dispatch(showRaffleSettingsDialogAct({raffleId: raffle._id, raffleName: raffle.name, showPercentage: raffle.showPercentage }))}
                    > <Settings/> </Button>
                  </TableCell>
                  <TableCell>
                    <ButtonGroup size="small">
                      <Button color="info" onClick={() => editRaffle(raffle._id)}>Editar</Button>
                      <Button color="error" disabled={raffle.status !== 0} onClick={() => dispatch(setRaffleForDisableAct({raffleId: raffle._id, raffleName: raffle.name}))}> <Lock fontSize="small"/> </Button>
                    </ButtonGroup>
                  </TableCell>

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}