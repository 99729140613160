import { Box, Card } from "@mui/material";
import { useAppDispatch } from "../app/hooks";
import RafflesList from "../features/raffles-list/components/raffles-list";
import { useEffect } from "react";
import { getRafflesThunk } from "../features/raffles-list/rafles-list.slice";
import { clearCurrentRaffleAct } from "../features/raffles/handle-raffle.slice";
import DisableRaffleDialog from "../features/raffles-list/components/disable-raffle-dialog";
import DialogSureNumberWinners from "../features/raffles-list/components/dialog-sure-number-winners";

export default function RafflesListView() {
  const dispatch = useAppDispatch()

  useEffect(()=> {
    dispatch(getRafflesThunk())
    dispatch(clearCurrentRaffleAct())
  }, [])

  return(
    <Box>
      <Card>
        <h1>Lista de sorteos</h1>
          <RafflesList/>
        <DisableRaffleDialog/>
        <DialogSureNumberWinners/>
      </Card>
    </Box>
  )
}