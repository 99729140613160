import { SellFormType } from "../../features/sell/sell.state";
import Api from "../axios";
import { RaffleSoldPercentageType } from "../models/raffle-sold-percetage.type";
import { SellType } from "../models/sell.type";
import { SoldNumberItem } from "../models/sold-number-item.type";
import { SoldNumberRowType } from "../models/sold-number-row.type";

export async function createSellPosReq(params : SellFormType): Promise<SellType>{
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `raffle-sells/create-sell-pos`, data: params})
    console.log('createSellPosReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON createSellPosReq');
    console.error({error});
    throw error;
  }
}

export async function generateNumbersSellReq({sellId} : {sellId : string}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `handle-payment/send-sell-pos/${sellId}`})
    console.log('generateNumbersSellReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON generateNumbersSellReq');
    console.error({error});
    throw error;
  }
}

export async function getRaffleSoldNumbersReq({raffleId} : {raffleId : string}): Promise<SoldNumberRowType[]>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `raffle-sells/numbers-sold/${raffleId}`})
    console.log('getRaffleSoldNumbers', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getRaffleSoldNumbers');
    console.error({error});
    throw error;
  }
}

export async function isSoldNumberReq({number, raffleId} : {number : string, raffleId: string}): Promise<SoldNumberItem[]>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `raffle-sells/is-sold-number/${raffleId}/${number}`})
    console.log('isSoldNumber', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON isSoldNumber');
    console.error({error});
    throw error;
  }
}

export async function getRaffleSoldPercentageReq({raffleId} : {raffleId : string}): Promise<RaffleSoldPercentageType>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `raffle-sells/get-percentage-sold/${raffleId}`})
    
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getRaffleSoldPercentage');
    console.error({error});
    throw error;
  }
}