import { createBrowserRouter } from "react-router-dom"
import UsersList from "../views/users-list-view"
import { HandleUser } from "../features/handle-user/HandleUser"
import Dashboard from "../features/dashboard/Dashboard"
import SignInView from "../features/signin/signin"
import LogoutView from "../features/logout/logout"
import HandleExpense from "../features/handle-expenses/handle-expenses"
import HandleRaffleView from "../views/handle-raffle-view"
import RafflesView from "../views/raffles-list-view"
import ReportsView from "../views/reports-view"
import SellView from "../views/sell-view"
import SetWinnerView from "../views/set-winner-view"

const router = createBrowserRouter([
  { path: "/", element: <SignInView /> },
  { path: "", element: <SignInView /> },
  { path: "/login", element: <SignInView /> },
  { path: "/logout", element: <LogoutView /> },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      { path: "user-list", element: <UsersList /> },
      { path: "handle-user", element: <HandleUser /> },

      //Raffles
      { path: "create-raffle", element: <HandleRaffleView /> },
      { path: "handel-raffle/:raffleId", element: <HandleRaffleView /> },
      { path: "raffles", element: <RafflesView /> },
      { path: "raffle-winner", element: <SetWinnerView /> },

      { path: "handle-user/:userId", element: <HandleUser /> },
      { path: "expenses", element: <HandleExpense /> },
      { path: "reports", element: <ReportsView /> },
      { path: "sell", element: <SellView /> },
    ],
  },
])

export default router
