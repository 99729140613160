import { Button, Grid, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppTextField from "../../../app/components/app-textfield";
import { FindInPage } from "@mui/icons-material";
import DialogInfoSoldNumber from "./dialog-info-sold-number";
import { changeInputFindNumberAct, findSoldNumberInfo } from "../set-winner.slice";

export default function FindSoldNumber() {
  const dispatch = useAppDispatch()
  const { numberForFind, form: {raffleId} } = useAppSelector((state) => state.setWinner)
  const { raffles } = useAppSelector((state) => state.raflesList)

  const changeInput = ({val} : {name: string, val: string}) => {
    dispatch(changeInputFindNumberAct(val))
  }

  const raffleSelected = raffles.find(r => r._id === raffleId)
  const findNumber = () => {
    if(raffleSelected === undefined) return
    if(raffleId !== "" && numberForFind.length === raffleSelected.numberLength){
      dispatch(findSoldNumberInfo({raffleId, number: numberForFind}))
    }
  }
  return (
    <>
      <DialogInfoSoldNumber />
      <Paper sx={{padding: 2, marginBottom: 2}}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AppTextField value={numberForFind} label="Buscar number" name="numberForFind" onChange={changeInput} 
                hasError={raffleSelected !== undefined && numberForFind.length !== raffleSelected.numberLength}
                error="El numero no tiene la longitud correcta"
                inputProps={{maxLength: raffleSelected?.numberLength}}
                endComponent={<Button size="small" variant="outlined" color="success" onClick={findNumber}> <FindInPage/> </Button>}
              />
            </Grid>
          </Grid>
        </Paper>
    </>
  )
}