import DialogWinnerNumberSetted from "../features/set-winner/components/dialog-winner-number-setted";
import FindSoldNumber from "../features/set-winner/components/find-sold-number";
import SetWinnerForm from "../features/set-winner/components/set-winner-form";
import SoldNumbersList from "../features/set-winner/components/sold-numbers-list";

export default function SetWinnerView (){
  return (
    <>
      <SetWinnerForm/>
      <FindSoldNumber/>
      <SoldNumbersList/>
      <DialogWinnerNumberSetted/>
    </>
  )
}