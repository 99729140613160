import { InputBaseComponentProps, TextField } from "@mui/material"

export interface InputAppProps {
  placeholder: string
  readonly?: boolean
  value: any
  onChange?: ( val: any, name: string) => void
  name?: string
  type?: string
  inputProps?: InputBaseComponentProps
  required?: boolean
  disabled?: boolean
}

export default function InputApp({
  name="", onChange= ()=>{}, readonly = false, placeholder, value, type= "text", disabled = false, required= false, inputProps = undefined
}: InputAppProps) {
  return (
    <TextField
      size="small"
      style={{ marginBlock: 10 }}
      fullWidth
      required={required}
      id={name}
      name={name}
      inputProps={inputProps}
      disabled={disabled}
      type={type}
      label={placeholder.toUpperCase()}
      value={value}
      onChange={(e) => onChange(e.target.value, name)}
    />
  )
}
