import ReportsFilter from "../features/reports/components/reports-filter";
import ReportSellsTable from "../features/reports/components/reports-sells-table";

export default function ReportsView()  {
  return (
    <>
      <ReportsFilter />
      <ReportSellsTable />
    </>
  )
}