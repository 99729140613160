import { Box, Button, Card, Grid } from "@mui/material";
import AppTextField from "../../../app/components/app-textfield";
import AppSelector from "../../../app/components/app-selector";
import LoadingIndicator from "../../../app/components/loading-indicator";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect } from "react";
import { changeInputSellAct, generateNumbersSellThunk, getRaffleSoldPercentageThunk, getRafflesThunk, setNMinTicketsAct, setShowSureSellDialogAct, setTicketPriceAct } from "../sell.slice";
import DialogSureSaveSell from "./dialog-sure-make-sell";
import DialgoSuccessSell from "./dialog-succes-sell";
import { max } from "date-fns";

export default function SellForm() {
  const dispatch = useAppDispatch()
  const { loading, raffles, formSell, nMinTickets, ticketPrice, newSell, rafflePercentage  } = useAppSelector(state => state.sell )

  const { document, email, nTickets, name, phone, raffle } = formSell

  useEffect(() => {
    dispatch(getRafflesThunk())
  }, [])

  useEffect(() => {
    if(newSell !== undefined) {
      dispatch(generateNumbersSellThunk(newSell._id))
    }
  }, [newSell, dispatch])

  const changeInput = (data : {name: string, val: string}) => {
    let d = data
    
    if(d.name === 'nTickets') {
      d.val = parseInt(d.val) > maxTicketsToSold ? maxTicketsToSold.toFixed(0) : d.val
    }

    dispatch(changeInputSellAct(d))
    if(data.name === 'raffle') {
      const raffleIndex = raffles.findIndex(r => r._id === data.val)
      if(raffleIndex !== -1) {
        dispatch(setNMinTicketsAct(raffles[raffleIndex].nTickets))
        dispatch(setTicketPriceAct(raffles[raffleIndex].ticketPrice))
        dispatch(changeInputSellAct({name: 'nTickets', val: raffles[raffleIndex].nTickets}))
        dispatch(getRaffleSoldPercentageThunk(raffles[raffleIndex]._id))
      }
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if(document.trim() === '' || email.trim() === '' || name.trim() === '' || phone.trim() === '' || raffle.trim() === '') {
      alert('Debe llenar todos los campos')
      return
    }
    dispatch(setShowSureSellDialogAct(true))
  }

  const maxTicketsToSold = rafflePercentage !== undefined ? rafflePercentage!.remaining || 0 : 0;

  return (
    <>
      <DialogSureSaveSell />
      <DialgoSuccessSell />
      <LoadingIndicator open={loading}/>
      <Card >
        <Box padding={1} component={'form'} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AppSelector name="raffle" value={raffle} options={raffles} label="Sorteo" onChange={changeInput}/>
            </Grid>
            <Button ></Button>
            <Grid item xs={12}>
              <AppTextField name="name" label="Nombre" value={name} onChange={changeInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppTextField name="document" label="Documento" value={document} onChange={changeInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppTextField name="phone" label="Telefono" value={phone} onChange={changeInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppTextField name="email" label="Correo" value={email} onChange={changeInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppTextField name="nTickets" label="Cantidad" value={nTickets} inputProps={{max: maxTicketsToSold}} type="number" onChange={changeInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppTextField readonly value={nTickets * ticketPrice} name="nTickets" label="Total" type="number" inputProps={{min: nMinTickets}} />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" type="submit" fullWidth > CREAR VENTA </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
      
    </>
  )
} 