import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SellFormType, SellSliceState } from "./sell.state";
import { getRafflesForSell } from "../../app/services/raffle.service";
import { createSellPosReq, generateNumbersSellReq, getRaffleSoldPercentageReq } from "../../app/services/sell.service";

const initFormSell = {
  document: "1110535005",
  email: "cristhiangrojas@gmail.com",
  name: "Christian",
  nTickets: 3,
  phone: "3108834323",
  raffle: ""
}

const initialState: SellSliceState = {
  loading: false,
  nMinTickets: 1,
  ticketPrice: 0,
  raffles: [],
  formSell: initFormSell,
  showSureSellDialog: false,
  showSuccessSellDialog: false
}
export const getRafflesThunk = createAsyncThunk( "SellSlice/getRafflesThunk", async () =>  await getRafflesForSell())

export const createSellPosThunk = createAsyncThunk( "SellSlice/createSellPosThunk", async (params: SellFormType) => await createSellPosReq(params))

export const generateNumbersSellThunk = createAsyncThunk( "SellSlice/generateNumbersSellThunk", async (sellId: string) => await generateNumbersSellReq({sellId}))

export const getRaffleSoldPercentageThunk = createAsyncThunk( "SellSlice/getRaffleSoldPercentageThunk", async (raffleId: string) => await getRaffleSoldPercentageReq({raffleId}))

export const SellSliceSlice = createSlice({
  name: "SellSlice",
  initialState,
  reducers: {
    changeInputSellAct: (state, action: PayloadAction<{name: string, val: any}>) => {
      state.formSell[action.payload.name] = action.payload.val
      if(action.payload.name === 'nTickets') {
        state.formSell.total = state.formSell.nTickets * state.nMinTickets
      }
    },
    setShowSureSellDialogAct: (state, action: PayloadAction<boolean>) => {
      state.showSureSellDialog = action.payload
    },
    setNMinTicketsAct: (state, action: PayloadAction<number>) => {
      state.nMinTickets = action.payload
    },
    setTicketPriceAct: (state, action: PayloadAction<number>) => {
      state.ticketPrice = action.payload
    },
    setShowSuccessSellDialogAct: (state, action: PayloadAction<boolean>) => {
      state.showSuccessSellDialog = action.payload
     }
  },
  extraReducers: (builder) => {
    builder.addCase(getRafflesThunk.fulfilled, (state, action) => {
      state.raffles = action.payload  
    }).addCase(createSellPosThunk.fulfilled, (state, action) => {
      state.newSell = action.payload
      state.formSell = initFormSell
    }).addCase(generateNumbersSellThunk.fulfilled, (state, action) => {
      state.showSureSellDialog = false
      state.showSuccessSellDialog = true
      state.newSell = undefined
    }).addCase(getRaffleSoldPercentageThunk.fulfilled, (state, action) => {
      state.rafflePercentage = action.payload
    })

    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("SellSlice"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("SellSlice"), (state) => {
      state.loading = false
    })
  },
})
export const { changeInputSellAct, setShowSureSellDialogAct, setNMinTicketsAct, setTicketPriceAct, setShowSuccessSellDialogAct } =SellSliceSlice.actions
export default SellSliceSlice.reducer