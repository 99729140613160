import { AssignmentInd, AttachMoney, CalendarMonth, Info, Person, Visibility } from "@mui/icons-material";
import { TableRow, TableCell, Button, Box, createTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { dateUTCToFriendly } from "../../../utils/date.utils";
import { numberToCurrency } from "../../../utils/numbers.utils";
import DialogSellData from "./dialog-sell-data";
import { getSellDataThunk } from "../reports.slice";
import { resolvePaymentMethod, resolveStatus, resolveUserRefered } from "../../../utils/sell.utils";
import { TableVirtuoso } from "react-virtuoso";
import { ReportSellRow } from "../../../app/models/report-sell-row.type";
import VirtuosoTableComponents from "../../../app/components/table-virtual-component";
import { ThemeProvider } from "@emotion/react";

const theme = createTheme({
  components: {
    MuiTableCell: { styleOverrides: { root: { padding: '0px 8px', },}},
    MuiTableRow: { styleOverrides: { root: { backgroundColor: 'white', },}}
  },
})

export default function ReportSellsTable() {
  const dispatch = useAppDispatch()
  const {sellRows} = useAppSelector((state) => state.reports)

  return (
    <ThemeProvider theme={theme}>
      <DialogSellData/>
      <Box sx={{height: 500}}>
        <TableVirtuoso
          data={sellRows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() =>
            <TableRow>
              <TableCell sx={{width: 150}} align="center"> <CalendarMonth/> </TableCell>
              <TableCell sx={{width: 150}}> <Person/> </TableCell>
              <TableCell sx={{width: 100}} align="center"> <AttachMoney/> </TableCell>
              <TableCell sx={{width: 100}} align="right"> Metodo </TableCell>
              <TableCell sx={{width: 100}} align="center"> <Info/> </TableCell>
              <TableCell sx={{width: 100}} align="center"> <Visibility/> </TableCell>
              <TableCell sx={{width: 100}} align="center"> <AssignmentInd/> </TableCell>
          </TableRow>
          }
          itemContent={(index, row: ReportSellRow) =>( <>
            <TableCell align="center"> {dateUTCToFriendly(row.date)} </TableCell>
              <TableCell> {row.name} </TableCell>
              <TableCell align="right"> $ {numberToCurrency(row.valuePayment)} </TableCell>
              <TableCell align="right"> {resolvePaymentMethod(row.paymentMethod)} </TableCell>
              <TableCell align="center"> {resolveStatus(row.status)} </TableCell>
              <TableCell align="center"> <Button size="small" variant="outlined" onClick={() => dispatch(getSellDataThunk(row._id))}> <Visibility fontSize="small"/> </Button> </TableCell>
              <TableCell>
                {row.paymentMethod === 1 ? resolveUserRefered(row.user) : row.code}
              </TableCell>
          </>)}
          fixedFooterContent={() => <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell align="right"> $ {numberToCurrency(sellRows.reduce((prev, curr) => prev + (curr.status === 1 ? curr.valuePayment: 0), 0))} </TableCell>
            <TableCell ></TableCell>

          </TableRow>}
        />
      </Box>
    </ThemeProvider>
  )
}