import { Box, IconButton, Paper, TableCell, TableRow, createTheme } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import VirtuosoTableComponents from "../../../app/components/table-virtual-component";
import { SoldNumberRowType } from "../../../app/models/sold-number-row.type";
import { CalendarMonth, Check, FindInPage, MonetizationOn, Numbers, Sort } from "@mui/icons-material";
import { dateUTCToFriendly } from "../../../utils/date.utils";
import { ThemeProvider } from "@emotion/react";
import { findSoldNumberInfo } from "../set-winner.slice";

const theme = createTheme({

  components: {
    MuiTableCell: { styleOverrides: { root: { padding: '0px 8px', },}},
    MuiTableRow: { styleOverrides: { root: { backgroundColor: 'white', },}}
  },
})

export default function SoldNumbersList() {
  const dispatch = useAppDispatch()
  const { soldNumbers, form } = useAppSelector((state) => state.setWinner)
  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{padding: 2, marginBottom: 2}}>
        <Box sx={{height: 500}}>
          <TableVirtuoso
            data={soldNumbers}
            components={VirtuosoTableComponents}
            fixedHeaderContent={() =>
              <TableRow>
                <TableCell sx={{width: 70}} align="left"> <Sort/> </TableCell>
                <TableCell sx={{width: 150}} align="center"> <CalendarMonth/> </TableCell>
                <TableCell sx={{width: 150}} align="center"> <Numbers/> </TableCell>
                <TableCell sx={{width: 50}}> <FindInPage/> </TableCell>
            </TableRow>
            }
            itemContent={(index, row: SoldNumberRowType) =>( <>
              <TableCell sx={{width: 70}}> {row.consecutive} 
                {(row.typeWinner === 1 && row.winner) && <Check fontSize="small" color="success"/>} 
                {(row.typeWinner === 0 && row.winner) && <MonetizationOn fontSize="small" color="success"/>} 
              </TableCell>
              <TableCell align="center"> {dateUTCToFriendly(row.createdAt)} </TableCell>
              <TableCell align="center"> {row.numberStr} </TableCell>
              <TableCell sx={{width: 50}}> 
                <IconButton size="small" color="info"
                  onClick={() => dispatch(findSoldNumberInfo({raffleId: row.raffle, number: row.numberStr}))}
                > 
                  <FindInPage fontSize="small"/> 
                </IconButton> 
              </TableCell>
            </>)}
            fixedFooterContent={() => <TableRow>
              <TableCell colSpan={3} align="center">Total numeros: {soldNumbers.length}</TableCell>
            </TableRow>}
          />
        </Box>
      </Paper>
    </ThemeProvider>
  )
}