/* eslint-disable react-hooks/exhaustive-deps */
import { Edit, Route, Settings, SettingsEthernet, Shield } from "@mui/icons-material"
import { Button, IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useEffect, useState } from "react"
import { fetchUsersThunk, openSetUserCodeDialogAct } from "../user-list.slice"
import { Link } from "react-router-dom"
import SetUserCodeDialog from "./set-user-code-dialog"
import LoadingIndicator from "../../../app/components/loading-indicator"

export default function UsersListTable(){
  const [showSnackBar, setShowSnackBar] = useState(false)

  const { users, loading } = useAppSelector((state: RootState) => state.users)

  const dispatch = useAppDispatch()
  
  useEffect(() => {
    if(users.length === 0){
      dispatch(fetchUsersThunk())
    }
  }, [users])

  const urlApi = import.meta.env.VITE_LANDING_PAGE

  return (
    <>
      <Snackbar open={showSnackBar} autoHideDuration={6000} onClose={() => setShowSnackBar(false)} message="Copied toclipboard" anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}/>
      <LoadingIndicator open={loading}/>
      <SetUserCodeDialog />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> <Settings /> </TableCell>
              <TableCell>L</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>PHONE</TableCell>
              <TableCell>CODIGO</TableCell>              
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((el, i) => {
              return (
                <TableRow key={"user"+el._id}>
                  <TableCell> {el.level === 0 ? <Shield/> : <Route/>  } </TableCell>
                  <TableCell>
                    <IconButton size="small" sx={{padding: "2px"}} component={Link} to={`/dashboard/handle-user/${el._id}`} color="warning"> 
                      <Edit fontSize="small"/> 
                    </IconButton>
                  </TableCell>
                  <TableCell>{el.email}</TableCell>
                  <TableCell>{el.phone}</TableCell>
                  <TableCell> 
                    <>
                      {!el.code && <IconButton color="primary" onClick={() => dispatch(openSetUserCodeDialogAct({userId: el._id!, userName: el.name}))}> <SettingsEthernet/> </IconButton>}
                      {el.code &&  <Tooltip 
                        title={`${urlApi}?code=${el.code}`} placement="top" children={<div>{el.code}</div>} 
                        onClick={() => {
                          navigator.clipboard.writeText(`${urlApi}?code=${el.code}`)
                          setShowSnackBar(true)
                        }}
                      />  
                      }
                    </>
                    </TableCell> 
                </TableRow>
              )
            })}
          </TableBody>

        </Table>
      </TableContainer>
    </>
  )
}