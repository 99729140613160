import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import PeopleIcon from "@mui/icons-material/People"
import { Link } from "react-router-dom"
import { AddBusiness, Airplay, Analytics, EmojiEvents, List, Margin, MonetizationOn, PersonAdd, PriceChange, QueuePlayNext } from "@mui/icons-material"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { TableVirtuoso } from "react-virtuoso"

interface RouteItemI {
  to: string
  Icon: any
  title: string
}

export function MenuItems() {
  
  const {currentUser} = useAppSelector((state: RootState) => state.login)
  
  
  const mapRoutes: RouteItemI[] = [


    { to: "/dashboard/create-raffle", Icon: <QueuePlayNext />, title: "Agregar Sorteo" },
    { to: "/dashboard/raffles", Icon: <Airplay />, title: "Sorteos" },

    { to: "/dashboard/user-list", Icon: <PeopleIcon />, title: "Usuarios" },
    { to: "/dashboard/handle-user", Icon: <PersonAdd />, title: "Crear usuario" },
    { to: "/dashboard/reports", Icon: <Analytics />, title: "Reportes" },
    { to: "/dashboard/sell", Icon: <MonetizationOn />, title: "Vender" },
    { to: "/dashboard/raffle-winner", Icon: <EmojiEvents />, title: "Ganador" },


    { to: "/dashboard/expenses", Icon: <PriceChange />, title: "Gastos" },
    // { to: "/logout", Icon: <Logout />, title: "Salir" },
    
    
    //{ to: "/dashboard/create-card", Icon: <Margin />, title: "Crear Tarjeta" },
    //{ to: "/dashboard/capital-contribute", Icon: <AddBusiness />, title: "Aporte Capital" },
    //{ to: "/dashboard/closure", Icon: <Analytics />, title: "Cuadre" },
    //{ to: "/dashboard/cards-lists", Icon: <List />, title: "Lista" },
  ]
  
  const mapRoutesVentor: RouteItemI[] = [
    { to: "/dashboard/sell", Icon: <MonetizationOn />, title: "Vender" }
    // { to: "/logout", Icon: <Logout />, title: "Salir" },
  ]
  
  const mapRoutesReferer: RouteItemI[] = [
    { to: "/dashboard/reports", Icon: <MonetizationOn />, title: "Reportes" }
    // { to: "/logout", Icon: <Logout />, title: "Salir" },
  ]

  return (
    <React.Fragment>
        {(currentUser?.level === 0 ? mapRoutes : (currentUser?.level === 1 ? mapRoutesReferer : mapRoutesVentor)).map((el, i) => {
        return (
          <Link key={"mainMenu" + i} to={el.to}>
            <ListItemButton>
              <ListItemIcon>{el.Icon}</ListItemIcon>
              <ListItemText primary={el.title} />
            </ListItemButton>
          </Link>
        )
      })}
    </React.Fragment>
  )
}
