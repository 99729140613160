import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import UserInterface from "../../app/models/user-interface"
import { getUserByIdReq, sendUserService, updateUserService } from "../../app/services/users.service"
import { pushAlertAction } from "../dashboard/dashboard.slice"
import { store } from "../../app/store"
import { fetchUsersThunk } from "../users-list/user-list.slice"

export interface HandleUserState {
  currentUser?: UserInterface,
  created: boolean,
  loading: boolean,
  userId?: string
}

const initialState: HandleUserState = {
  created: false, loading: false
}

export const fetchUserByIdThunk = createAsyncThunk( "handleUser/setUserById", async (userId: string) => {
    const user = await getUserByIdReq(userId)
    return {user}
  },
)

export const createUserThunk = createAsyncThunk("handleUser/createUser",  async(dataUser: any) => {
  const createUser = await sendUserService({user: dataUser})
  if(createUser === true){
    store.dispatch(fetchUsersThunk())
    return createUser
  }else {
    store.dispatch(pushAlertAction({message: createUser, title: createUser}))
  }
})

export const updateUserTnunk = createAsyncThunk("handleUser/updateUser", async({dataUser, userId}:{dataUser: any, userId: string}) => {
  const { name, lastName, email, phone, password, level} = dataUser
  const updateUser = await updateUserService({user: {
    name, lastName, email, phone, level,
    password: password !== "" ? password : undefined
  }, userId})

  if(updateUser === true){
    store.dispatch(fetchUsersThunk())
    return true
  }else {
    store.dispatch(pushAlertAction({message: updateUser, title: updateUser}))
  }
  return updateUser
})

export const SliceNameSlice = createSlice({
  name: "SliceName",
  initialState,
  reducers: {
    fetchUser: (state, action: PayloadAction<{ user: UserInterface }>) => {
      state.currentUser = action.payload.user
    },
    userCreated: (state, action: PayloadAction) => {
      state.created = true
    },
    removeCurrentUserAction: (state) => {
      state.currentUser = undefined
    },
    setUserIdAction: (state, action: PayloadAction<string | undefined>) => {
      state.userId = action.payload
    },
    resetHandleUserStateAction: (state) => state = initialState
  },
  extraReducers(builder) {
    builder.addCase(createUserThunk.pending, (state) => {state.loading = true})
    builder.addCase(updateUserTnunk.pending, (state) => {state.loading = true})
    builder.addCase(fetchUserByIdThunk.pending, (state) => {state.loading = true})
    builder.addCase(createUserThunk.fulfilled, (state, action) => {
      state.loading = false
      if(action.payload === true) state.created = true
    })
    builder.addCase(updateUserTnunk.fulfilled, (state, action) =>{
      state.loading = false
      state.created = true
    })
    builder.addCase(fetchUserByIdThunk.fulfilled , (state, action: PayloadAction<{ user: UserInterface }>) => {
      const {payload} = action
      console.log({payload});
      state.currentUser = action.payload.user
      state.loading = false
    })
  },
})



export const { fetchUser, userCreated, removeCurrentUserAction, resetHandleUserStateAction, setUserIdAction } = SliceNameSlice.actions

export default SliceNameSlice.reducer
