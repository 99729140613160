import { Delete, ZoomOutMap } from "@mui/icons-material";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";

export default function ImgItemHandler({src, srcSet, onClickDelete = () => {}, onClickPreview = () => {}} : {src: string, srcSet: string, onClickDelete?:() => void, onClickPreview?: () => void}) {
  return (
    <ImageListItem cols={3}>
      <img 
        src={src} 
        srcSet={srcSet}
        alt=""
        loading="lazy"
      />
      <ImageListItemBar 
        actionIcon={
          <>
            <IconButton color="error" onClick={onClickDelete}> <Delete/> </IconButton>
            <IconButton color="info" onClick={onClickPreview}> <ZoomOutMap/> </IconButton>
          </>
        }
      />
    </ImageListItem>
  )
}