import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserInterface from "../../app/models/user-interface"
import { AppThunk } from "../../app/store"
import { checkCodeRegisteredReq, fetchUsers, updateUserCodeReq } from "../../app/services/users.service"
import { UsersListState } from "./components/user-list.state"

const userListInitState: UsersListState = {
  users: [],
  loading: false,
}

export const checkIfCodeRegisteredThunk = createAsyncThunk( "usersListSlice/checkIfCodeRegisteredThunk", async (code: string) => await checkCodeRegisteredReq({code}))

export const fetchUsersThunk = createAsyncThunk( "usersListSlice/fetchUsers", async () =>  await fetchUsers())

export const setUserCodeThunk = createAsyncThunk( "userListSlice/setUserCodeThunk", async (params: {userId: string, code: string}) => await updateUserCodeReq(params))

export const usersListSlice = createSlice({
  name: "usersListSlice",
  initialState: userListInitState,
  reducers: {
    setUserList: (state, action: PayloadAction<UserInterface[]>) => {
      state.users = action.payload
    },
    openSetUserCodeDialogAct: (state, action: PayloadAction<{userId: string, userName: string}>) => {
      state.setUserCodeDialog = {userId: action.payload.userId, userName: action.payload.userName, code: "", codeValidated: undefined, validatingCode: false}
    },
    closeSetUserCodeDialogAct: (state) => {
      state.setUserCodeDialog = undefined
    },
    changeUserCodeInputAct: (state, action: PayloadAction<string>) => {
      if(state.setUserCodeDialog){
        state.setUserCodeDialog.code = action.payload
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUsersThunk.fulfilled, (state, action) => {      
      state.users = action.payload
    }).addCase(setUserCodeThunk.fulfilled, (state, action) => {
      const userIndex = state.users.findIndex((el) => el._id === action.payload._id)  
      state.setUserCodeDialog = undefined
      if(userIndex !== -1) state.users[userIndex] = action.payload
    }).addCase(checkIfCodeRegisteredThunk.pending, (state) => {
      state.setUserCodeDialog!.validatingCode = true
    }).addCase(checkIfCodeRegisteredThunk.fulfilled, (state, action) => {
      state.setUserCodeDialog!.codeValidated = action.payload
    })

    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes('usersListSlice'), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes('usersListSlice'), (state) => {
      state.loading = false
    })
  },
})

export const { setUserList, closeSetUserCodeDialogAct, changeUserCodeInputAct, openSetUserCodeDialogAct } = usersListSlice.actions

export const getUserList = (): AppThunk => async (dispatch, getState) => {}

export default usersListSlice.reducer
