export class RaffleUtils {

  static resolveStatus = (status: number): string => {
    switch(status) {
      case 0: return "Activo"
      case 1: return "Bloqueado"
      case 2: return "Finalizado"
      default: return "--"
    }
  }
}