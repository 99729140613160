import { Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import { dateUTCToFriendly } from "../../../utils/date.utils";
import { Close } from "@mui/icons-material";
import { closeDialogInfoNumberAct } from "../set-winner.slice";

export default function DialogInfoSoldNumber() {
  const dispatch = useAppDispatch() 
  const { infoNumber, numberForFind } = useAppSelector((state) => state.setWinner)
  return (
    <>
      <Dialog open={infoNumber !== undefined}>
        <IconButton className="closeDialog" onClick={() => dispatch(closeDialogInfoNumberAct())}><Close/> </IconButton>
        <DialogTitle> 
          {infoNumber === null &&  `No encontrado`} 
          {infoNumber && `Informacion numero`}
          </DialogTitle>
        <DialogContent sx={{minWidth: 500}}>
        {infoNumber === null &&  <Typography variant="h6"> El numero  <strong> {numberForFind}</strong> no se ha vendido </Typography> } 
          {infoNumber && <>
            <Grid container>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Numero"
                      secondary={infoNumber!.numberStr}
                      />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Fecha"
                      secondary={dateUTCToFriendly(infoNumber!.createdAt)}
                    />
                  </ListItem >
                  <ListItem>
                    <ListItemText
                      primary="Es ganador"
                      secondary={infoNumber!.typeWinner === 0 ? "Premio Mayor" : infoNumber!.typeWinner === 0 ? "Premio menor" : "No"}
                    />
                  </ListItem >
                </List>

              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Nombre"
                      secondary={infoNumber!.raffleSellModel.name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Correo"
                      secondary={infoNumber!.raffleSellModel.email}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Numero"
                      secondary={infoNumber!.raffleSellModel.phone}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </>}
        </DialogContent>
      </Dialog>
    </>
  )
}