import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RaffleForDisable, RafflesListState, RaffleSureNumberWinner, RaffleSureNumberWinners } from "./raffles-list.state"
import { disableRaffleReq, getRafflePercentageSoldReq, getRafflesReq, setSureNumbersWinnersReq, toggleShowRafflePercentageReq } from "../../app/services/raffle.service"
import { RaffleInterface } from "../../app/models/raffle-interface"

const initialState: RafflesListState = {
  raffles: [],
  loading: true
}

export const getRafflesThunk = createAsyncThunk("RafflesListSlice/getRafflesThunk", async () =>  await getRafflesReq())

export const disableRaffleThunk = createAsyncThunk( "RafflesListSlice/disableRaffleThunk", async (raffleId: string) =>  await disableRaffleReq({raffleId}))

export const setNumbersSureWinnersThunk = createAsyncThunk( "RafflesListSlice/setNumbersSureWinnersReq", async (params : {raffleId: string, numbers: RaffleSureNumberWinner[]}) => 
  await setSureNumbersWinnersReq(params)
)

export const getRaffleSoldPercentageThunk = createAsyncThunk( "RafflesListSlice/getRaffleSoldPercentageThunk", async (raffleId: string) => await getRafflePercentageSoldReq({raffleId}))

export const toggleShowPercentageRaffleThunk = createAsyncThunk( "RafflesListSlice/toggleShowPercentageRaffleThunk", async (params : {raffleId: string, show: boolean}) => {
  const show = await toggleShowRafflePercentageReq(params)
  return {show, raffleId: params.raffleId}
})

export const RafflesListSlice = createSlice({
  name: "RafflesListSlice",
  initialState,
  reducers: {
    setLoadingRafflesListAct: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    addRaffleToStackAct: (state, action: PayloadAction<RaffleInterface  >) => {
      state.raffles.push(action.payload)
    },
    setRaffleForDisableAct: (state, action: PayloadAction<RaffleForDisable | undefined>) => { 
      state.raffleForDisabled = action.payload
    },
    setDialogRaffleSureNumberWinnersAct: (state, action: PayloadAction<RaffleSureNumberWinners | undefined>) => { 
      console.log({action});
      state.dialogSureNumberWinners = action.payload
    },
    updateInputNumberSureNumberWinnersAct: (state, action: PayloadAction<string>) => { 
      state.dialogSureNumberWinners!.inputNumber = action.payload
    },
    updateInputNTicketsSureNumberWinnersAct: (state, action: PayloadAction<number>) => {
      state.dialogSureNumberWinners!.nTicket = action.payload
    },
    updateInputPrizeSureNumberWinnersAct: (state, action: PayloadAction<number>) => {
      state.dialogSureNumberWinners!.prize = action.payload
    },
    addNumberToSureNumberWinnersAct: (state, action: PayloadAction<{number: string, nTicket: number, prize: number}>) => {
      state.dialogSureNumberWinners!.numbers.push(action.payload)
      state.dialogSureNumberWinners!.inputNumber = ""
      state.dialogSureNumberWinners!.numbers.sort((a, b) => parseInt(a.number) - parseInt(b.number))
    },
    removeNumberToSureNumberWinnersAct: (state, action: PayloadAction<number>) => {
      state.dialogSureNumberWinners!.numbers.splice(action.payload, 1)
    },
    showRaffleSettingsDialogAct: (state, action: PayloadAction<{raffleId:string, raffleName: string, showPercentage: boolean}>) => {
      const { raffleId, raffleName, showPercentage } = action.payload
      state.raffleSettingsDialog = {
        raffleId, soldPercentage: 0,
        raffleName,
        showPercentage
      }
    },
    hideRaffleSettingsDialogAct: (state) => {
      state.raffleSettingsDialog = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRafflesThunk.fulfilled, (state, action) => {
      state.raffles = action.payload
      state.loading = false
    }).addCase(disableRaffleThunk.fulfilled, (state, action) => {
      const raffleIndex = state.raffles.findIndex(raffle => raffle._id === action.payload._id)  
      if(raffleIndex !== -1) {
        state.raffles[raffleIndex] = action.payload
      }
      state.raffleForDisabled = undefined
    }).addCase(setNumbersSureWinnersThunk.fulfilled, (state, action) => {
      const raffleIndex = state.raffles.findIndex(raffle => raffle._id === action.payload._id)  
      if(raffleIndex !== -1) {
        state.raffles[raffleIndex] = action.payload
      }
      state.dialogSureNumberWinners = undefined
    }).addCase(getRaffleSoldPercentageThunk.fulfilled, (state, action) => {
      state.raffleSettingsDialog!.soldPercentage = action.payload
    }).addCase(toggleShowPercentageRaffleThunk.fulfilled, (state, action) => {
      state.raffleSettingsDialog!.showPercentage = action.payload.show
      const indexRaffle = state.raffles.findIndex(raffle => raffle._id === action.payload.raffleId)
      if(indexRaffle !== -1) {
        state.raffles[indexRaffle].showPercentage = action.payload.show
      }
    })

    builder.addMatcher(action => action.type.endsWith('/pending') && action.type.includes('RafflesListSlice'), (state, action) => {
      state.loading = true
    }).addMatcher(action => action.type.endsWith('/fulfilled') && action.type.includes('RafflesListSlice'), (state, action) => {
      state.loading = false
    })
  
  }
})

export const { setLoadingRafflesListAct, addRaffleToStackAct, setRaffleForDisableAct, addNumberToSureNumberWinnersAct, setDialogRaffleSureNumberWinnersAct, updateInputNumberSureNumberWinnersAct, removeNumberToSureNumberWinnersAct, updateInputNTicketsSureNumberWinnersAct, updateInputPrizeSureNumberWinnersAct, hideRaffleSettingsDialogAct, showRaffleSettingsDialogAct } = RafflesListSlice.actions

export default RafflesListSlice.reducer