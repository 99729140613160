import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeDialogRaffleDoneAct } from "../set-winner.slice";
import { dateUTCToFriendly } from "../../../utils/date.utils";

export default function DialogWinnerNumberSetted() {
  const dispatch = useAppDispatch()
  const { showDialogSucessRaffleDone, form: {numberWinner, raffleId}, numberWinner: numberWinnerInfo } = useAppSelector((state) => state.setWinner)
  const { raffles } = useAppSelector((state) => state.raflesList)

  const raffleSelected = raffles.find((r) => r._id === raffleId)

  const closeDialog = () => dispatch(closeDialogRaffleDoneAct())

  return (
    <>
      <Dialog open={showDialogSucessRaffleDone}>
        <IconButton>  <Close/>  </IconButton>
        <DialogTitle> Sorteo finaliazdo correctamente </DialogTitle>
        <DialogContent> 
          <>
            Se ha definido correctamente el numero numero <strong>{numberWinner}</strong>  del sorteo <strong>{raffleSelected !== undefined && raffleSelected?.name} como ganador, sorteo finalizado</strong>   
          </>
          {numberWinnerInfo && <>
            <Grid container>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Numero"
                      secondary={numberWinnerInfo!.numberStr}
                      />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Fecha"
                      secondary={dateUTCToFriendly(numberWinnerInfo!.createdAt)}
                    />
                  </ListItem >
                  <ListItem>
                    <ListItemText
                      primary="Es numero ganador?"
                      secondary={numberWinnerInfo!.typeWinner === 0 ? "Si" : "No"}
                    />
                  </ListItem >
                </List>

              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Nombre"
                      secondary={numberWinnerInfo!.raffleSellModel.name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Correo"
                      secondary={numberWinnerInfo!.raffleSellModel.email}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Numero"
                      secondary={numberWinnerInfo!.raffleSellModel.phone}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </>}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="success" onClick={closeDialog}> ACEPTAR  </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}