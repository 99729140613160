import { Box, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Switch } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect } from "react";
import { Close } from "@mui/icons-material";
import { getRaffleSoldPercentageThunk, hideRaffleSettingsDialogAct, toggleShowPercentageRaffleThunk } from "../rafles-list.slice";

export default function RaffleSettingsDialog() {
  const dispatch = useAppDispatch()
  const { raffleSettingsDialog } = useAppSelector((state) => state.raflesList)

  useEffect(() => {
    if(raffleSettingsDialog !== undefined) {
      dispatch(getRaffleSoldPercentageThunk(raffleSettingsDialog.raffleId))
    }
  }, [raffleSettingsDialog, dispatch])

  const closeDialog = () => {
    dispatch(hideRaffleSettingsDialogAct())
  }
  return (
    <>
      <Dialog open={raffleSettingsDialog !== undefined}>
        <IconButton className="closeDialog" onClick={closeDialog}> <Close/> </IconButton>
        {raffleSettingsDialog !== undefined && <>
          <DialogTitle> Configuracion {raffleSettingsDialog!.raffleName} </DialogTitle>
          <DialogContent sx={{minWidth: 500}}>
              <Grid container alignItems={'center'}>
                <Grid item xs={9}>
                  <FormControlLabel label="Mostrar porcentaje" control={
                    <Switch checked={raffleSettingsDialog!.showPercentage} onChange={(e, c) => dispatch(toggleShowPercentageRaffleThunk({raffleId: raffleSettingsDialog.raffleId, show: c}))} /> } 
                  />
                </Grid>
                <Grid item xs={3}>
                  {raffleSettingsDialog.soldPercentage}%
                </Grid>
              </Grid>
              <Divider/>
          </DialogContent>
        </>}
      </Dialog>
    </>
  )
}