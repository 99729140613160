import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Close, RemoveCircle } from "@mui/icons-material";
import { addNumberToSureNumberWinnersAct, removeNumberToSureNumberWinnersAct, setDialogRaffleSureNumberWinnersAct, setNumbersSureWinnersThunk, updateInputNTicketsSureNumberWinnersAct, updateInputNumberSureNumberWinnersAct, updateInputPrizeSureNumberWinnersAct } from "../rafles-list.slice";
import InputApp from "../../../app/components/input-app";

export default function DialogSureNumberWinners() {
  const {dialogSureNumberWinners, raffles} = useAppSelector((state) => state.raflesList)
  const dispatch = useAppDispatch()
  const closeDialog = () => dispatch(setDialogRaffleSureNumberWinnersAct(undefined))

  const pushAddSureNumberWinner = () => {
    let repeatedNumber = false
    dialogSureNumberWinners!.numbers.forEach((number) => {
      if(number.number === dialogSureNumberWinners!.inputNumber || number.nTicket === dialogSureNumberWinners!.nTicket) repeatedNumber = true
    })
    if(repeatedNumber) return alert('No puedes agregar un numero o consecutivo que ya existe')
    if(!dialogSureNumberWinners!.inputNumber || !dialogSureNumberWinners!.nTicket || !dialogSureNumberWinners!.prize) return
    dispatch(addNumberToSureNumberWinnersAct({number: dialogSureNumberWinners!.inputNumber, nTicket: dialogSureNumberWinners!.nTicket, prize: dialogSureNumberWinners!.prize}))
  }

  return (
    <Dialog open={dialogSureNumberWinners !== undefined}>
      <IconButton className="closeDialog" onClick={closeDialog}> <Close /> </IconButton>
     {dialogSureNumberWinners !== undefined && <>
        <DialogTitle>Numeros fijos de premio menor</DialogTitle>
        <DialogContent sx={{minWidth: '500px'}}>
          Define los numeros fijos de premio menor
          <Grid component={'form'} container alignItems={"center"} spacing={1} onSubmit={(e) =>{
            e.preventDefault()
            pushAddSureNumberWinner()
          }}>
            <Grid item xs={3}>
              <InputApp inputProps={{maxLength: dialogSureNumberWinners.maxLength}}  placeholder="Numero" value={dialogSureNumberWinners!.inputNumber} onChange={(val) => dispatch(updateInputNumberSureNumberWinnersAct(val.replace(/\D/g, '')))} />
            </Grid>
            <Grid item xs={3}>
              <InputApp inputProps={{maxLength: dialogSureNumberWinners.maxLength}}  placeholder="Consecutivo" value={dialogSureNumberWinners!.nTicket} onChange={(val) => dispatch(updateInputNTicketsSureNumberWinnersAct(val.replace(/\D/g, '')))} />
            </Grid>
            <Grid item xs={4}>
              <InputApp type="number" inputProps={{maxLength: dialogSureNumberWinners.maxLength}}  placeholder="Premio" value={dialogSureNumberWinners!.prize} onChange={(val) => dispatch(updateInputPrizeSureNumberWinnersAct(val.replace(/\D/g, '')))} />
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth variant="contained" onClick={pushAddSureNumberWinner}> Agregar </Button>
            </Grid>
            <Grid item xs={12}>
              <List>
                {dialogSureNumberWinners!.numbers.map((number, index) => 
                <ListItem key={index+"numbersSireWOnner"}
                  secondaryAction={<IconButton onClick={() => dispatch(removeNumberToSureNumberWinnersAct(index))} color="error"> <RemoveCircle/> </IconButton>}
                >{
                  <Grid container>
                    <Grid item xs={4}>
                      {number.number} 
                    </Grid>
                    <Grid item xs={4}>
                      {number.nTicket}
                    </Grid>
                    <Grid item xs={4}>
                      {number.prize}
                    </Grid>
                  </Grid>
                }</ListItem>)}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingRight: 2}}>
          <Button size="small" variant="contained" color="error" onClick={closeDialog}> CANCELAR</Button>
          <Button size="small" variant="contained" color="success" onClick={() => 
          {
            dispatch(setNumbersSureWinnersThunk({
              raffleId: dialogSureNumberWinners.raffleId,
              numbers: dialogSureNumberWinners!.numbers
            }))
          }
          }> GUARDAR </Button>
        </DialogActions>
      </>}
    </Dialog>
  )
}