export const resolveStatus = (status: number) => {
  //pending:0, //payed:1, //Cancelled:2  //Refun: 3 //All -1
  switch(status) {
    case 0: return "Pendiente"
    case 1: return "Pagado"
    case 2: return "Cancelado"
    case 3: return "Reembolsado"
    default: return "--"
  } 
}

export const resolvePaymentMethod = (method: number) => {
  switch(method) {
    case 0: return "Tarjeta"
    case 1: return "Efectivo"
    default: return "--"
  }
}

export const resolveUserRefered = (user: any[]): string => {
  if(user.length > 0) return user[0].name
  return "--"
  
}