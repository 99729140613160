import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Close } from "@mui/icons-material";
import { disableRaffleThunk, setRaffleForDisableAct } from "../rafles-list.slice";

export default function DisableRaffleDialog() {
  const {raffleForDisabled} = useAppSelector(state => state.raflesList)
  const dispatch = useAppDispatch()
  const closeDialog = () => dispatch(setRaffleForDisableAct(undefined))
  return (
    <>
      <Dialog open={raffleForDisabled !== undefined}>
        <IconButton className="closeDialog" onClick={closeDialog}> <Close/> </IconButton>
        {raffleForDisabled !== undefined && <>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>Esta seguro que deseas deshabilitad la Rifa: </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={closeDialog} > CANCELAR </Button>
            <Button variant="contained"  onClick={() => dispatch(disableRaffleThunk(raffleForDisabled!.raffleId))} > DESHABILITAR </Button>
          </DialogActions>
        </>}
      </Dialog>
    </>
  )
}