import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Check, Close, Warning } from "@mui/icons-material";
import { changeUserCodeInputAct, checkIfCodeRegisteredThunk, closeSetUserCodeDialogAct, setUserCodeThunk } from "../user-list.slice";
import AppTextField from "../../../app/components/app-textfield";

export default function SetUserCodeDialog() {
  const {setUserCodeDialog} = useAppSelector((state) => state.users)
  const dispatch = useAppDispatch() 
  const closeDialog = () => dispatch(closeSetUserCodeDialogAct())
  
  const saveUserCode = () => {
    if(setUserCodeDialog !== undefined && setUserCodeDialog.code.length > 4){
      dispatch(setUserCodeThunk({code: setUserCodeDialog.code, userId: setUserCodeDialog.userId}))
    }
  }

  const changeInputCode = (val: string) => {
    dispatch(changeUserCodeInputAct(val))
    if(val.length > 4){
      dispatch(checkIfCodeRegisteredThunk(val))
    }
  }
  return (
    <>
      <Dialog open={setUserCodeDialog !== undefined}>
        <IconButton className="closeDialog" onClick={closeDialog}> <Close/> </IconButton>
        {setUserCodeDialog !== undefined &&  <>
          <DialogTitle sx={{marginRight: 3}}>Definir codigo  referido {setUserCodeDialog.userName}</DialogTitle>
          <DialogContent>
              <AppTextField label="Codigo"  onChange={({name, val}) => changeInputCode(val)} name="code" value={setUserCodeDialog.code}
                error={"El codigo debe tener al menos 5 caracteres"} hasError={setUserCodeDialog.code.length < 4}
                endComponent={<>
                  {setUserCodeDialog.codeValidated === true && <Check color="success" />} 
                  {setUserCodeDialog.codeValidated === false && <Close color="error" />} 
                  {setUserCodeDialog.codeValidated === undefined && setUserCodeDialog.validatingCode === false && <Warning color="secondary" />} 
                  {setUserCodeDialog.codeValidated === undefined && setUserCodeDialog.validatingCode === true && <CircularProgress color="secondary" />} 
                </>
                }
              />
          </DialogContent>
          <DialogActions>
            <Button size="small" color="error" onClick={closeDialog}> CANCELAR </Button>
            <Button size="small" color="primary" onClick={saveUserCode} disabled={!(setUserCodeDialog.codeValidated === true)}> GUARDAR </Button>
          </DialogActions>
        </>}
      </Dialog>
    </>
  )
}