import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import usersListReducer from "../features/users-list/user-list.slice"
import handleUserSlice from "../features/handle-user/handle-user.slice"
import SigInReducer from "../features/signin/signin.slice"
import dashboardSlice from "../features/dashboard/dashboard.slice"
// import handleCardSlice from "../features/handle-card/handle-card.slice"
// import cardsListSlice from "../features/cards-list/cards-list.slice"
import handleExpensesSlice from "../features/handle-expenses/handle-expenses.slice"
// import addPaymentSlice from "../features/add-payment/add-payment.slice"
// import historyCardsSlice from "../features/history-cards/history-cards.slice"
// import capitalContributeSlice from "../features/capital-contribute/capital-contribute.slice"
// import closureSlice from "../features/closure/closure.slice"
import handleRaffeSlice from "../features/raffles/handle-raffle.slice"
import rafflesListSlice  from "../features/raffles-list/rafles-list.slice"
import reportsSlice from "../features/reports/reports.slice"
import sellSlice from "../features/sell/sell.slice"
import setWinnerSlice from "../features/set-winner/set-winner.slice"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    dashboard: dashboardSlice,
    expenses: handleExpensesSlice,
    handleUser: handleUserSlice,
    login: SigInReducer,
    sell: sellSlice,
    users: usersListReducer,

    reports: reportsSlice,
    //Raffle
    raffle: handleRaffeSlice,
    raflesList: rafflesListSlice,
    setWinner: setWinnerSlice
    
    //Unused
    // addPayment: addPaymentSlice,
    // handleCard: handleCardSlice,
    // cardsList: cardsListSlice,
    // historyCards: historyCardsSlice,
    // capitalContribute: capitalContributeSlice,
    // closure: closureSlice,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
