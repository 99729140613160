import { Grid, IconButton, Paper } from "@mui/material";
import AppSelector from "../../../app/components/app-selector";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppTextField from "../../../app/components/app-textfield";
import { changeInputSetWinnerAct, getSoldNumbersThunk, showDialogSureNumberWinnerAct } from "../set-winner.slice";
import { useEffect } from "react";
import { getRafflesThunk } from "../../raffles-list/rafles-list.slice";
import LoadingIndicator from "../../../app/components/loading-indicator";
import { Check } from "@mui/icons-material";
import DialogSureSetWinnerNumber from "./dialog-sure-set-winner-number";

export default function SetWinnerForm() {
  const dispatch = useAppDispatch()
  const { raffles } = useAppSelector((state) => state.raflesList) 
  const { form, loading } = useAppSelector((state) => state.setWinner) 

  useEffect(() => {
    dispatch(getRafflesThunk())
  }, [])

  const raffleSelected = raffles.find((r) => r._id === form.raffleId)
  
  const hasError = () => {
    if(raffleSelected === undefined) return ""
    if(raffleSelected !== undefined && form.numberWinner.length < raffleSelected.numberLength ) return `El numero debe tener ${raffleSelected.numberLength} digitos` 
    return ""
  }

  const changeInput = ({name, val} : {name: string, val: string}) => {
    dispatch(changeInputSetWinnerAct({name, val}))
    if(name === "raffleId" && val !== "") dispatch(getSoldNumbersThunk({raffleId: val}))
  }

  const pushWinner = () => {  
    if(hasError()!.length > 0) return
    dispatch(showDialogSureNumberWinnerAct(true))
  }
  const disableInputNumberWinner = raffleSelected === undefined || raffleSelected!.status! > 0

  return(
    <>
      <DialogSureSetWinnerNumber/>
      <LoadingIndicator open={loading}/>
      <Paper sx={{padding: 2, marginBottom: 2}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AppSelector value={form.raffleId} options={raffles.filter((r) => r.status !== 1)} label="Sorteo" name="raffleId" onChange={changeInput} 
              />
          </Grid>
          <Grid item xs={6}>
            <AppTextField label="Numero ganador" disabled={disableInputNumberWinner} value={form.numberWinner}  error={hasError()} name="numberWinner" onChange={changeInput} hasError={!disableInputNumberWinner && hasError()!.length > 0}
              inputProps={{maxLength: raffleSelected?.numberLength}}
              endComponent={<IconButton onClick={pushWinner}> <Check/> </IconButton>}
            />
          </Grid>
        </Grid>
      </Paper>

    </>
  )
}