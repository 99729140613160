import { RaffleSureNumberWinner } from "../../features/raffles-list/raffles-list.state";
import { HandleRaffleForm } from "../../features/raffles/handle-raffle.state";
import { FileUtils } from "../../utils/file.utils";
import Api from "../axios";
import { RaffleInterface } from "../models/raffle-interface";
import { RaffleType } from "../models/raffle-type";
import { SoldNumberItem } from "../models/sold-number-item.type";

export async function sendRaffleHeadFormReq({raffleForm, raffleId} : {raffleForm : HandleRaffleForm, raffleId?: string}): Promise<RaffleInterface>  {
  try {
    const api = Api.getInstance()
    console.log({raffleForm});
    const {nTickets, ticketPrice, numberLength} = raffleForm
    const response = await api.post({path: `raffles/${raffleId !== undefined ? `update-raffle/${raffleId}` : 'create-raffle'}`, data: {...raffleForm, nTickets: Number(nTickets), ticketPrice: Number(ticketPrice), numberLength: Number(numberLength)} })
    console.log('sendRaffleHeadFormReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON sendRaffleHeadFormReq');
    console.error({error});
    throw error;
  }
}

export async function disableRaffleReq({raffleId} : {raffleId : string}): Promise<RaffleInterface>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `raffles/disable/${raffleId}`})
    console.log('disableRaffleReq', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON disableRaffleReq');
    console.error({error});
    throw error;
  }
}

export async function setSureNumbersWinnersReq({raffleId, numbers} : {raffleId : string, numbers: RaffleSureNumberWinner[]}): Promise<RaffleInterface>{
  try {
    const api = Api.getInstance()
    const response = await api.put({path: `raffles/set-raffle-numbers-sure-winners/${raffleId}`, data: {numbers}})
    console.log('setSureNumbersWinners', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON setSureNumbersWinners');
    console.error({error});
    throw error;
  }
}

export async function getRafflesReq(): Promise<any>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: 'raffles/get-raffles'})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getRafflesReq');
    console.error({error});
    throw error;
  }
}

export async function getRaffleByIdReq({raffleId} : {raffleId : string}): Promise<any>  {
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `raffles/get-raffle-by-id/${raffleId}`})
    console.log('getRaffleById', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getRaffleById');
    console.error({error});
    throw error;
  }
}

export async function uploadRaffleImagesReq({raffleId, files} : {raffleId : string, files: any}): Promise<any>  {
  try {
    const api = Api.getInstance()
    
    const filesFormat = await files.map((file: any) => FileUtils.dataUrlToFile(file.src, file.name))
    const filesFormatted: Blob[] = await Promise.all(filesFormat)
  
    const formData = new FormData()
    for(const f of filesFormatted) {
      formData.append("files", f)
    }
    const response = await api.post({path: `raffles/upload-raffle-image/${raffleId}`, data: formData, isFormData: true})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON uploadRaffleImages');
    console.error({error});
    throw error;
  }
}

export async function removeImgFromRaffleReq({raffleId, raffleImg} : {raffleId : string, raffleImg: string}): Promise<RaffleInterface>  {
  try {
    const api = Api.getInstance()
    const response = await api.delete({path: `raffles/remove-file-from-raffle/${raffleId}/${raffleImg}`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON removeImgFromRaffle');
    console.error({error});
    throw error;
  }
}

export async function getRafflesForSell(): Promise<RaffleType[]>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `raffles/get-raffles-for-sell`})
    
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getRafflesForSell');
    console.error({error});
    throw error;
  }
}

export async function setNumberWinnerReq({raffleId, number} : {raffleId : string, number: string}): Promise<{success: boolean, infoWinner: SoldNumberItem[]}>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `raffle-sells/set-winner-number/${raffleId}/${number}`})
    console.log('customerAnswerReq', {response});
    const { error } = response
    if(error == null) {
      return {success: true, infoWinner: response.result.infoWinner}
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON customerAnswerReq');
    console.error({error});
    throw error;
  }
}

export async function getRafflePercentageSoldReq({raffleId} : {raffleId  : string}): Promise<any>{
  try {
    const api = Api.getInstance()
    const response = await api.get({path: `raffle-sells/get-percentage-sold/${raffleId}`})
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getRafflePercentageSold');
    console.error({error});
    throw error;
  }
}

export async function toggleShowRafflePercentageReq({raffleId, show} : {raffleId : string, show: boolean}): Promise<boolean>{
  try {
    const api = Api.getInstance()
    const response = await api.patch({path: `raffles/toggle-show-percentage/${raffleId}/${show}`})
    const { error } = response
    if(error == null) {
      return response.result.showPercentage
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON toggleShowRafflePercentageReq');
    console.error({error});
    throw error;
  }
}