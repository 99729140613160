import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setNumberWinnerThunk, showDialogSureNumberWinnerAct } from "../set-winner.slice";

export default function DialogSureSetWinnerNumber() {
  const dispatch = useAppDispatch()
  const { showDialogSureNumberWinner, form: {numberWinner, raffleId} } = useAppSelector((state) => state.setWinner)
  const { raffles } = useAppSelector((state) => state.raflesList)

  const raffleSelected = raffles.find((r) => r._id === raffleId)

  const setNumberWinner = () => dispatch(setNumberWinnerThunk({number: numberWinner, raffleId}))

  return (
    <Dialog open={showDialogSureNumberWinner === true}>
      <DialogContent sx={{minWidth: 500}}>
        <Typography>
          Esta seguro que deseas definir el numero <strong>{numberWinner}</strong>  del sorteo <strong>{raffleSelected !== undefined && raffleSelected?.name}</strong> ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="success" onClick={setNumberWinner}> ACEPTAR</Button>
        <Button variant="outlined" color="error" onClick={() => dispatch(showDialogSureNumberWinnerAct(false))}> CANCELAR</Button>
      </DialogActions>
    </Dialog>
  )
}