import { Dialog, DialogTitle, DialogContent, Button, IconButton, Typography, Divider } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Close } from "@mui/icons-material";
import { removeSellDataAct } from "../reports.slice";
import { resolveStatus } from "../../../utils/sell.utils";
import { numberToCurrency } from "../../../utils/numbers.utils";
import { dateUTCToFriendly } from "../../../utils/date.utils";

export default function DialogSellData() {
  const { sellData } = useAppSelector ((state) => state.reports )
  const dispatch = useAppDispatch()
  return (
    <>
      <Dialog open={sellData !== undefined}>
        <IconButton size="small" className="closeDialog" onClick={() => dispatch(removeSellDataAct())}> <Close /></IconButton>

        {sellData !== undefined && <>
          <DialogTitle sx={{marginRight: 3}}>Informacion de venta </DialogTitle>
          <DialogContent>
            <div>
              <Typography variant="body1"> <b>Fecha: </b> {dateUTCToFriendly(sellData.date)} </Typography>
              <Typography variant="body1"> <b>Nombre: </b> {sellData.name} </Typography>
              <Typography variant="body1"> <b>Correo: </b> {sellData.email} </Typography>
              <Typography variant="body1"> <b>Telefono: </b> {sellData.phone} </Typography>
              <Typography variant="body1"> <b>Valor: </b> $ {numberToCurrency(sellData.valuePayment)} </Typography>
              <Typography variant="body1"> <b>Estado: </b> {resolveStatus(sellData.status)} </Typography>
            </div>
            <Divider className='divider' />
            <Typography variant="body1"> <b>Numero(s): </b> </Typography>

            {sellData.numbers.map((num, index) => (
              <Typography>{num.toString().padStart(sellData.raffle.numberLength, '0')}</Typography>
            ))
            }
          </DialogContent>
        </>}
      </Dialog>
    </>
  )
}