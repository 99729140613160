import Api from "../axios";
import { ReportSellsFilter } from "../models/report-sell-filter.type";
import { ReportSellRow } from "../models/report-sell-row.type";

export async function getReportSellWithFitlersReq({dateFrom, dateTo, raffleId, status, excludeDates, userId} : ReportSellsFilter): Promise<ReportSellRow[]>{
  try {
    const api = Api.getInstance()
    const response = await api.post({path: `raffle-sells/get-sells`, data: {dateFrom, dateTo, raffleId, status, excludeDates, userId}})
    console.log('getReportSellWithFitlers', {response});
    const { error } = response
    if(error == null) {
      return response.result
    }else {
      throw error
    }
  } catch (error) {
    console.error('ERROR ON getReportSellWithFitlers');
    console.error({error});
    throw error;
  }
}