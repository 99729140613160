import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ReportsSliceState } from "./reports.state";
import { getCurrenDateUtil } from "../../utils/date.utils";
import { getReportSellWithFitlersReq } from "../../app/services/report.service";
import { ReportSellsFilter } from "../../app/models/report-sell-filter.type";
import { getSellDataReq } from "../../app/services/payments.service";

const initialState: ReportsSliceState = {
  loading: false,
  filter: {
    dateTo: getCurrenDateUtil(),
    dateFrom: getCurrenDateUtil(),
    raffleId: '',
    status: -1,
    excludeDates: false,
    userId: ''
  },
  sellRows: []
}
export const reportGetSellThunk = createAsyncThunk( "ReportsSlice/getReportSellWithFitlersReq", async (params: ReportSellsFilter) =>  await getReportSellWithFitlersReq(params))

export const getSellDataThunk = createAsyncThunk( "ReportsSlice/getSellDataThunk", async (sellId: string) => await getSellDataReq({sellId}))

export const ReportsSliceSlice = createSlice({
  name: "ReportsSlice",
  initialState,
  reducers: {
    incrementByAmount: (state, action: PayloadAction<any>) => {
    },
    changeDateReportAct: (state, action: PayloadAction<{dateFrom: string, dateTo: string}>) => { 
      state.filter.dateFrom = action.payload.dateFrom
      state.filter.dateTo = action.payload.dateTo
    },
    changeRaffleReportAct: (state, action: PayloadAction<string>) => {
      state.filter.raffleId = action.payload
    },
    removeSellDataAct: (state) => { 
      state.sellData = undefined
    },
    changeFilterExcludeDatesAct: (state, action: PayloadAction<boolean>) => {
      state.filter.excludeDates = action.payload
    },
    changeFilterUserIdAct: (state, action: PayloadAction<string>) => {
      state.filter.userId = action.payload
    },
    changeStatusReportAct: (state, action: PayloadAction<number>) => {  
      state.filter.status = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(reportGetSellThunk.fulfilled, (state, action) => {
      state.sellRows = action.payload 
    }).addCase(getSellDataThunk.fulfilled, (state, action) => {
      state.sellData = action.payload
    })

    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("ReportsSlice"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("ReportsSlice"), (state) => {
      state.loading = false
    })
  },
})
export const { changeDateReportAct, changeRaffleReportAct, removeSellDataAct, changeStatusReportAct, changeFilterExcludeDatesAct, changeFilterUserIdAct } =ReportsSliceSlice.actions
export default ReportsSliceSlice.reducer